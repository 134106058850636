/* eslint-disable global-require */
import {
  BCard, BSpinner, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BButton,
} from 'bootstrap-vue'
import { toggleConfirmPasswordVisibility, togglePasswordVisibility } from '@core/mixins/ui/forms'
// import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import { NoSpace } from '@/libs/helpers'

export default {
  components: {
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BSpinner,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility, toggleConfirmPasswordVisibility],
  data() {
    return {
      // error: '',
      passwordNew: '',
      confirmedPassword: '',
      status: '',
      loading: false,
      successChanged: false,
      // validation rules
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      NoSpace,
      validationPassword: [
        {
          key: 'min8',
          status: false,
          text: 'Minimal 8 karakter',
          error: '8 karakter',
        },
        {
          key: 'minCapital',
          status: false,
          text: 'Minimal 1 huruf besar A-Z',
          error: '1 huruf besar',
        },
        {
          key: 'minText',
          status: false,
          text: 'Minimal 1 huruf kecil a-z',
          error: '1 huruf kecil',
        },
        {
          key: 'minNum',
          status: false,
          text: 'Minimal 1 angka 0-9',
          error: '1 angka',
        },
        {
          key: 'minChar',
          status: false,
          text: "Minimal 1 symbol : !@#$%^&*()_=+<>?{}[/;:'\",.",
          error: '1 symbol',
        },
        {
          key: 'maxChar',
          status: true,
          error: 'Maximal 32 karakter',
        },
      ],
      isFocusPassword: false,
      isValidPassword: false,
      error: {
        password: '',
        confirmPassword: '',
      },
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    isValidPassword() {
      return this.validationPassword.every(e => e.status === true)
    },
    renderListErrorPassword() {
      return this.validationPassword.filter(data => data.status === false)
        .length > 0 && this.isFocusPassword
        ? `${this.validationPassword
          .filter(data => data.status === false && data.key === 'maxChar')
          .map(e => e.error)
          .join(', ')}`
        : ''
    },
  },
  methods: {
    formaterPassword(value) {
      const regex = {
        uppercase: /[A-Z]/,
        lowercase: /[a-z]/,
        number: /[0-9]/,
        symbol: new RegExp("[!@#$%^&*()_=+<>?{}\\[\\]/;:'\",.\\\\]"),
      }

      const validations = [
        { key: 'min8', test: value.length >= 8 },
        { key: 'minCapital', test: regex.uppercase.test(value) },
        { key: 'minText', test: regex.lowercase.test(value) },
        { key: 'minNum', test: regex.number.test(value) },
        { key: 'minChar', test: regex.symbol.test(value) },
      ]

      validations.forEach(validation => {
        const rule = this.validationPassword.find(v => v.key === validation.key)
        if (rule) rule.status = validation.test
      })

      this.isFocusPassword = true

      if (this.confirmedPassword) {
        this.error.confirmPassword = value !== this.confirmedPassword
          ? 'Konfirmasi Password harus sesuai'
          : ''
      }

      const errorMessages = [
        { condition: value.length === 0, message: 'Password harus diisi' },
        { condition: value.length < 8, message: 'Minimal 8 karakter' },
        { condition: value.length > 32, message: 'Maksimal 32 karakter' },
        { condition: !regex.uppercase.test(value), message: 'Password harus mengandung minimal 1 huruf besar' },
        { condition: !regex.lowercase.test(value), message: 'Password harus mengandung minimal 1 huruf kecil' },
        { condition: !regex.number.test(value), message: 'Password harus mengandung minimal 1 angka' },
        { condition: !regex.symbol.test(value), message: 'Password harus mengandung minimal 1 simbol' },
      ]

      const error = errorMessages.find(e => e.condition)
      this.error.password = error ? error.message : ''

      return value
    },
    formaterConfirmPassword(value) {
      if (value !== this.passwordNew) {
        this.error.confirmPassword = 'Konfirmasi Password harus sesuai'
      } else if (value.length > 32) {
        this.error.confirmPassword = 'Maksimal 32 karakter'
      } else {
        this.error.confirmPassword = ''
      }
      return value
    },
    handleBlurPw() {
      this.isFocusPassword = false
    },
    showmodal() {
      this.$refs['ubah-password'].toggle('#togggle-btn')
    },
    disableSubmit() {
      return !(!this.error.password && !this.error.confirmPassword && this.passwordNew && this.confirmedPassword)
    },
    submit() {
      if (!this.disableSubmit()) {
        this.loading = true
        // eslint-disable-next-line
          this.$http.post('/v1/partner/reset-password', {
          code: this.$route.query.code,
          password: this.passwordNew,
        })
          .then(() => {
            this.successChanged = true
          }).catch(err => {
            this.loading = false
          })
      }
    },
  },
}
